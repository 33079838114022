<template>
  <div class="d-flex jusitfy-between mb-3">
    <a-card class="col-4 mx-1 cursor-pointer" :loading="false" title="My profile" @click="$router.push({name: 'profile-info'})">View more</a-card>
    <a-card class="col-4 mx-1 cursor-pointer" :loading="false" title="My Services" @click="$router.push({name: 'services-info'})">View more</a-card>
    <a-card class="col-4 mx-1 cursor-pointer" :loading="false" title="My Numbers" @click="$router.push({name: 'numbers-info'})"
      >View more</a-card
    >
  </div>
</template>

<script>
export default {}
</script>
<style scoped>
.cursor-pointer{
 cursor: pointer;
}
</style>